<template>
  <el-dialog
    title="Complete Document"
    :fullscreen="true"
    :visible.sync="dialogdocumentStatus"
    @close="selfClose"
  >
    <div class v-loading="usersDataLoading">
      <el-row :gutter="20">
        <el-col :span="16">
          <el-form v-model="userDocumentFormData">
            <el-form-item label="Document Valid From">
              <el-date-picker
                type="date"
                v-model="userDocumentFormData.valid_from"
              ></el-date-picker>
            </el-form-item>

            <el-form-item label="Document Expires">
              <el-date-picker
                type="date"
                v-model="userDocumentFormData.expires_at"
              ></el-date-picker>
            </el-form-item>
            <el-divider class="mb-05"></el-divider>

            <el-button @click="completeEmployeeDocument"> Submit </el-button>
          </el-form>
        </el-col>
      </el-row>
    </div>
  </el-dialog>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "ExecuteCompanyDocs-CompleteEmpDoc",
  props: ["employeeDocumentId", "userDocumentFormData"],
  components: {},

  data() {
    return {
      visible: false,
      dialogdocumentStatus: true,
      usersDataLoading: false,
      usersLogsLoading: false,
      documentUsersData: [],
      documentUsersLogsData: [],
      urlParams: {},
      completedialogVisible: false,
    };
  },
  computed: {
    ...mapGetters("employeeDocuments", ["getEmployeeDocumentCompleteStatus"]),
  },
  mounted() {},
  methods: {
    //getting user document logs
    async completeEmployeeDocument() {
      this.usersDataLoading = true;
      let params = {
        id: this.employeeDocumentId,
        valid_from: this.userDocumentFormData.valid_from,
        expires_at: this.userDocumentFormData.expires_at,
      };
      await this.$store.dispatch(
        "employeeDocuments/completeEmployeeDocument",
        params
      );

      if (this.getEmployeeDocumentCompleteStatus) {
        this.$notify.success({
          title: "Success",
          message: "Document Completed successfully",
        });
        this.usersDataLoading = false;
        this.updateDocument();
      } else {
        this.$notify.error({
          title: "Error",
          message: "Error at updating document data",
        });
      }
      this.usersDataLoading = false;
    },
    selfClose() {
      this.dialogdocumentStatus = false;
      this.$emit("dialog:close", false);
    },

    // getting user document data

    getUserFullName(data) {
      if (!data) return "-";
      return data.email;
    },

    updateDocument() {
      this.$emit("dialog:close", true);
    },
  },
  filters: {
    getUserFullName(data) {
      if (!data) return "-";
      return data.first_name
        ? data.first_name
        : "" + " " + data.last_name
        ? data.last_name
        : "";
    },
  },
  beforeDestroy() {
    this.$store.commit("employeeDocuments/setEmployeeDocumentCompleteStatus",null,{root:true})
  }
};
</script>

<style>
.user-data-logs {
  max-height: 100%;
  overflow-y: scroll;
}
</style>