<template>
  <el-dialog
    title="Document Status"
    :fullscreen="true"
    :visible.sync="dialogdocumentStatus"
    @close="selfClose"
  >
    <div class v-loading="usersDataLoading">
      <el-row :gutter="20">
        <el-col :span="15">
          <div class="vue-data-table-default" v-if="documentUsersData">
            <el-table :data="documentUsersData" style="width: 100%">
              <el-table-column label="User Name" min-width="180">
                <template slot-scope="scope">
                  <span class="usr-name d-block yale-blue">
                    {{
                      scope.row.user_details
                        ? getUserFullName(scope.row.user_details)
                        : ""
                    }}
                  </span>
                  <span class="usr-email medium">
                    {{
                      scope.row.user_details
                        ? scope.row.user_details.email_id
                        : ""
                    }}
                  </span>
                </template>
              </el-table-column>
              <el-table-column label="Recipient" width="150">
                <template slot-scope="scope">
                  <span class="rich-black">{{ scope.row.name }}</span>
                  <el-tag v-if="scope.row.has_approval_access">
                    Aproval Autority
                  </el-tag>
                </template>
              </el-table-column>

              <el-table-column label="Send Status" width="150">
                <template slot-scope="scope">
                  <span class="success" v-if="scope.row.sent_status">
                    <i class="el-icon-success"></i> Sent
                  </span>
                  <div
                    class="usr-email medium black-coral line-height-2"
                    v-if="scope.row.sent_status"
                  >
                    Signed on {{ scope.row.sent_status }}
                  </div>
                  <span class="failure" v-if="!scope.row.sent_status">
                    <i class="el-icon-warning"></i> Pending
                  </span>
                </template>
              </el-table-column>
              <el-table-column label="E-Signature" width="160">
                <template slot-scope="scope">
                  <div v-if="scope.row.e_signature_status == true">
                    <el-tag type="success" size="small">&#8226; Done</el-tag>
                    <div
                      class="usr-email medium black-coral line-height-2"
                      v-if="scope.row.e_signature_date"
                    >
                      Signed on
                      {{
                        scope.row.e_signature_date
                          ? $moment(scope.row.e_signature_date).format("ll")
                          : "-"
                      }}
                    </div>
                  </div>
                  <div v-else>
                    <el-tag type="danger" size="small">&#8226; Pending</el-tag>
                    <!-- <div
                        class="usr-email medium black-coral line-height-2"
                        v-if="scope.row.e_signature_status"
                      >Visited on {{ scope.row.due_on }}</div> -->
                  </div>
                </template>
              </el-table-column>

              <el-table-column label="Actions" width="140">
                <template slot-scope="scope">
                  <ul class="status-block-buttons">
                    <li
                      v-if="
                        scope.row.sent_status && !scope.row.e_signature_status
                      "
                    >
                      <el-button
                        type="success"
                        size="mini"
                        @click="sendRemainder(scope.row.user_details._id)"
                      >
                        <i class="el-icon-refresh"></i> Remainder
                      </el-button>
                    </li>
                    <li v-else>--</li>
                    <!-- <li v-if="!scope.row.sent_status">
                      <el-button
                        type="success"
                        size="mini"
                        @click="resendDocument(scope.row.user_details._id)"
                      >
                        <i class="el-icon-refresh"></i> Resend
                      </el-button>
                    </li> -->
                  </ul>
                </template>
              </el-table-column>
            </el-table>
          </div>

          <el-alert
            v-if="rejectUserDocumentReason"
            title="Document Rejected"
            type="error"
            :description="rejectUserDocumentReason"
            center
          >
          </el-alert>
        </el-col>
        <el-col :span="9">
          <div class="user-data-logs" v-loading="usersLogsLoading">
            <h4 class="h4 yale-blue" v-if="this.documentUsersLogsData">
              <i class="el-icon-wind-power"></i>
              <span class="pl-5">Logs </span>
            </h4>
            <el-divider class="mt-05 mb-0"></el-divider>
            <div class="logs-list">
              <el-timeline class="each-log">
                <el-timeline-item
                  v-for="(log, index) in this.documentUsersLogsData"
                  :key="index"
                >
                  <TrackingStepElement :log="log"></TrackingStepElement>
                </el-timeline-item>
              </el-timeline>
            </div>
          </div>
        </el-col>
      </el-row>
    </div>
  </el-dialog>
</template>

<script>
import { mapGetters } from "vuex";
import TrackingStepElement from "../../widgets/TrackingStepElement";
export default {
  props: ["employeeDocumentId"],
  components: {
    TrackingStepElement,
  },
  data() {
    return {
      visible: false,
      dialogdocumentStatus: false,
      usersDataLoading: false,
      usersLogsLoading: false,
      documentUsersData: [],
      documentUsersLogsData: [],
      urlParams: {},
      completedialogVisible: false,
      rejectUserDocumentReason: "",
      isRejected: false,
    };
  },
  computed: {
    ...mapGetters("employeeDocumentUsers", [
      "getDocumentUsers",
      "getDocumentUserAcceptStatus",
      "getDocumentUserRejectStatus",
      "getDocumentUserLogs",
      "getDocumentUserRemainderStatus",
      "getUserDocumentResendStatus",
    ]),
  },
  mounted() {
    this.fetchDocumentUsersData();
    this.fetchDocumentUsersLogs();
  },
  methods: {
    //getting user document logs
    async fetchDocumentUsersLogs() {
      this.dialogdocumentStatus = true;

      this.usersLogsLoading = true;
      await this.$store.dispatch(
        "employeeDocumentUsers/fetchUserDocumentsLogs",
        {
          employee_document_id:
            this.$route.query.employee_document_id ||
            this.$route.params.employee_document_id,
        }
      );
      if (this.getDocumentUserLogs.success) {
        this.$notify({
          title: "Success",
          message: "Employee Document logs fetched successfully",
          type: "success",
        });
      } else {
        this.$notify.error({
          title: "Error",
          message: "This is an error",
        });
      }
      this.documentUsersLogsData = this.getDocumentUserLogs.data;
      this.usersLogsLoading = false;
    },
    selfClose() {
      this.dialogdocumentStatus = true;
      this.$emit("dialog:close", false);
    },
    // getting user document data
    async fetchDocumentUsersData() {
      this.dialogdocumentStatus = true;

      this.usersDataLoading = true;

      await this.$store.dispatch("employeeDocumentUsers/fetchDocumentUsers", {
        employee_document_id:
          this.$route.query.employee_document_id ||
          this.$route.params.employee_document_id,
      });
      this.documentUsersData = this.getDocumentUsers.employee_document_users;
      this.usersDataLoading = false;
      let isRejectedUser = this.documentUsersData.find(
        (x) => x.approval_status == "REJECTED"
      );
      if (isRejectedUser) {
        this.rejectUserDocument = true;
        this.rejectUserDocumentReason = isRejectedUser.document_rejected_notes;
      }
    },

    // accept user document
    async acceptUserDocument(user_id) {
      this.usersDataLoading = true;
      this.urlParams.employee_document_id =
        this.$route.query.employee_document_id ||
        this.$route.params.employee_document_id;
      this.urlParams.user_id = user_id;
      await this.$store.dispatch(
        "employeeDocumentUsers/acceptUserDocument",
        this.urlParams
      );

      if (this.getDocumentUserAcceptStatus.success) {
        this.$notify.success({
          title: "Success",
          message: this.getDocumentUserAcceptStatus.message,
        });
      } else {
        this.$notify.warning({
          title: "Warning",
          message: "Error in Accepting Employee Document.",
        });
      }
      this.fetchDocumentUsersData();
      this.usersDataLoading = false;
    },

    // reject user document
    async rejectUserDocument(user_id) {
      this.usersDataLoading = true;
      this.urlParams.employee_document_id =
        this.$route.query.employee_document_id ||
        this.$route.params.employee_document_id;
      this.urlParams.user_id = user_id;
      await this.$store.dispatch(
        "employeeDocumentUsers/rejectUserDocument",
        this.urlParams
      );

      if (this.getDocumentUserRejectStatus.success) {
        this.$notify.success({
          title: "Success",
          message: this.getDocumentUserRejectStatus.message,
        });
      } else {
        this.$notify.warning({
          title: "Warning",
          message: "Error in Rejecting Employee Document.",
        });
      }
      this.fetchDocumentUsersData();
      this.usersDataLoading = false;
    },

    async sendRemainder(user_id) {
      this.sendRemainderDialog = true;
      this.sendRemainderToUserID = user_id;
      await this.sendRemainderToUser(user_id);
    },

    async sendRemainderToUser(userId) {
      //this.sendReminder
      this.loading = true;
      this.urlParams.user_id = userId;
      this.urlParams.employee_document_id = this.employeeDocumentId;
      await this.$store.dispatch(
        "employeeDocumentUsers/sendRemainderToUser",
        this.urlParams
      );
      this.sendRemainderDialog = false;
      this.sendRemainderToUserID = null;
      if (this.getDocumentUserRemainderStatus.success) {
        this.$notify.success({
          title: "Success",
          message: "Employee Remainder Successfully.",
        });
      } else {
        this.$notify.warning({
          title: "Warning",
          message: "Error in Sending Employee Remainder.",
        });
      }
      this.fetchDocumentUsersData();
      this.loading = false;
    },
    async resendDocument(user_id) {
      this.loading = true;
      let params = { 
        document_user: user_id,
        employee_document_id: this.employeeDocumentId 
        }
      await this.$store.dispatch(
        "employeeDocumentUsers/resendUserDocument",
        params
      );
      if (this.getUserDocumentResendStatus) {
        this.$notify.success({
          title: "Success",
          message: "Document Resend Successfully.",
        });
      } else {
        this.$notify.warning({
          title: "Warning",
          message: "Error in Sending Document Resend.",
        });
      }
      this.fetchDocumentUsersData();
      this.loading = false;
    },

    getUserFullName(data) {
      if (!data) return "-";
      return data.email;
    },
  },
  filters: {
    getUserFullName(data) {
      if (!data) return "-";
      return data.first_name
        ? data.first_name
        : "" + " " + data.last_name
        ? data.last_name
        : "";
    },
  },
  beforeDestroy() {
    this.$store.commit("employeeDocumentUsers/setDocumentUsers",null,{root:true})
    this.$store.commit("employeeDocumentUsers/setDocumentUserAcceptStatus",null,{root:true})
    this.$store.commit("employeeDocumentUsers/setDocumentUserRejectStatus",null,{root:true})
    this.$store.commit("employeeDocumentUsers/setDocumentUserLogs",null,{root:true})
    this.$store.commit("employeeDocumentUsers/setDocumentUserRemainderStatus",null,{root:true})
    this.$store.commit("employeeDocumentUsers/setUserDocumentResendStatus",null,{root:true})
  }
};
</script>

<style lang="scss">
.user-data-logs {
  padding: 1em 0 0.5em 1em;
  background: #ffffff;
  border: 1px solid #efefef;
  .logs-list {
    max-height: calc(100vh - 170px);
    overflow-y: scroll;
    border-radius: 3px;
    .each-log {
      margin-top: 20px;
      .el-timeline-item {
        padding-bottom: 0px;
      }
      .el-timeline-item__wrapper {
        padding-left: 20px;
      }
      .el-timeline-item__node--normal {
        left: 0;
      }
      .el-timeline-item__tail {
        left: 5px;
        height: calc(100% + 10px);
      }
    }
  }
}
</style>